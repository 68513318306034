
export default {
	name: "CoreBlockContainerToggle",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		activeId: {
			type: String,
			required: true,
		},
	},
	computed: {
		blockChildren() {
			return this.settings?.blocks || [];
		},
	},
	methods: {
		toggleLabel(toggleIndex) {
			return this.settings[`label-${toggleIndex + 1}`] || "Toggle";
		},
		setToggle(event) {
			this.$emit("set-item", event);
		},
	},
};
