
export default {
	name: "CoreBlockContainerTabs",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		activeId: {
			type: String,
			required: true,
		},
	},
	computed: {
		blockChildren() {
			return this.settings?.blocks || [];
		},
	},
	methods: {
		setTab(event) {
			this.$emit("set-item", event);
		},
	},
};
